$(document).ready(function() {
  $('[data-toggle="tooltip"].nav-link').tooltip({
    placement: "bottom",
    trigger: "hover",
    delay: {
      "show": 200,
      "hide": 0
    }
  });

  var options = {
    trigger: 'hover',
    delay: {
      "show": 200,
      "hide": 0
    }
  };

  var tags = $('a[target="external"]:not(.no-icon)').add('a.external-icon');
  tags.each(function (index) {
    if (!$(this).attr("title")) {
      $(this).attr("title", "External Link");
    }
    $(this).attr("data-toggle", "tooltip");
  });
  tags.tooltip(options);

  tags = $('a[target="map"]:not(.no-icon)').add('a.map-icon');
  tags.each(function (index) {
    if (!$(this).attr("title")) {
      $(this).attr("title", "See OS Map");
    }
    $(this).attr("data-toggle", "tooltip");
  });
  tags.tooltip(options);

  tags = $('a[href^="mailto:"]:not(.no-icon)').add('a.mailto-icon');
  tags.each(function (index) {
    if (!$(this).attr("title")) {
      $(this).attr("title", "Email " + this.pathname);
    }
    $(this).attr("data-toggle", "tooltip");
  });
  tags.tooltip(options);
});
